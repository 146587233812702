var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex _bg-default _80w _100vh"},[(!_vm.loading)?_c('div',{staticClass:"_100top pa-3 _full-w"},[(_vm.thread.data)?_c('div',[_c('v-card',{staticClass:"radius-card width__thread"},[_c('section',[_c('div',{staticClass:"d-flex pa-3"},[_c('v-avatar',{staticClass:"mr-3"},[_c('img',{staticClass:"img-fit",attrs:{"src":_vm.thread.data.dataUser.photo_profile
                    ? (_vm.env + "/upload/photo_profile/" + (_vm.thread.data.dataUser.id) + "/" + (_vm.thread.data.dataUser.photo_profile))
                    : (_vm.env + "/images/logo.png"),"alt":""}})]),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.thread.data.dataUser.nama_lengkap))]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$date(_vm.thread.data.forum.created_at).fromNow()))])])],1)]),_c('section',{staticClass:"px-5 py-3"},[_c('h3',[_vm._v(_vm._s(_vm.thread.data.forum.judul))])]),(_vm.thread.data.forum.image)?_c('v-img',{staticClass:"img-fit",attrs:{"src":(_vm.env + "/forum/upload/" + (_vm.thread.data.dataUser.id) + "/" + (_vm.thread.data.forum.image))}}):_vm._e(),_c('article',{staticClass:"pa-5"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.thread.data.forum.isi)}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 100,
            }),expression:"{\n              arrow: true,\n              arrowType: 'round',\n              animation: 'fade',\n              theme: 'light',\n              maxWidth: 100,\n            }"}],attrs:{"text":"","color":"blue","content":"Comment"},on:{"click":_vm.toComment}},[_c('v-icon',[_vm._v("mdi-comment")])],1),_c('div',{staticClass:"px-3"},[(!_vm.thread.data.isLike)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 100,\n              }"}],attrs:{"text":"","color":"blue","content":"Like"},on:{"click":function($event){return _vm.like(_vm.thread.data.forum.id)}}},[_c('v-icon',[_vm._v("mdi-heart")]),(_vm.thread.data.likers > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.thread.data.likers)+" ")]):_vm._e()],1):_vm._e(),(_vm.thread.data.isLike)?_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.unlike(_vm.thread.data.forum.id)}}},[_c('v-icon',[_vm._v("mdi-heart")]),(_vm.thread.data.likers > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.thread.data.likers)+" ")]):_vm._e()],1):_vm._e()],1),(_vm.thread.data.dataUser.id == _vm.id)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 100,
            }),expression:"{\n              arrow: true,\n              arrowType: 'round',\n              animation: 'fade',\n              theme: 'light',\n              maxWidth: 100,\n            }"}],attrs:{"icon":"","color":"blue","content":"Edit"},on:{"click":function($event){return _vm.updateForum(_vm.thread.data.forum)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.thread.data.dataUser.id == _vm.id)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 100,
            }),expression:"{\n              arrow: true,\n              arrowType: 'round',\n              animation: 'fade',\n              theme: 'light',\n              maxWidth: 100,\n            }"}],attrs:{"icon":"","color":"blue","content":"delete"},on:{"click":function($event){return _vm.delForum(_vm.thread.data.forum.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1),_c('v-card',{staticClass:"mt-3 radius-card width__thread"},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('h3',[_vm._v("Komentar")])]),(!_vm.thread.data.dataComment.length)?_c('div',{staticClass:"d-flex algn-center justify-center flex-column"},[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h4',{staticClass:"text-center"},[_vm._v("No Comment")])]):_c('div',{staticClass:"pa-3"},_vm._l((_vm.thread.data.dataComment),function(comment,idx){return _c('v-card',{key:idx,staticClass:"radius-card pa-3 mb-2"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-3"},[_c('img',{staticClass:"img-fit",attrs:{"src":comment.photo_profile
                        ? (_vm.env + "/upload/photo_profile/" + (comment.user_id) + "/" + (comment.photo_profile))
                        : (_vm.env + "/images/logo.png"),"alt":""}})]),_c('div',{staticClass:"d-flex align-center"},[_c('h4',[_vm._v(_vm._s(comment.nama_lengkap))]),_c('span',{staticClass:"mx-1"},[_c('b',[_vm._v(".")])]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$date(comment.created_at).fromNow()))])])],1),(comment.id == _vm.id)?_c('div',[_c('v-btn',{attrs:{"icon":"","small":"","color":"blue"},on:{"click":function($event){return _vm.editComment(comment)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.delComment(comment.idComment)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('p',[_vm._v(_vm._s(comment.content))])])])}),1),_c('div',{staticClass:"d-flex flex-column pa-3"},[_c('v-textarea',{attrs:{"name":"comment","id":"fieldComment","hide-details":"","label":"Write something!","auto-grow":"","solo":""},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 blue",attrs:{"dark":""},on:{"click":function($event){return _vm.comment(_vm.thread.data.forum.id)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tooltip-check")]),_vm._v("Comment")],1)],1),_c('div',{attrs:{"id":"comment"}})],1)],1),(_vm.loading)?_c('div',{staticClass:"_100top pa-3 _full-w"},[_c('h3',{staticClass:"text-center"},[_vm._v("Loading...")])]):_vm._e(),_c('div',{staticClass:"pa-3"},[_c('UpdateForum',{attrs:{"dialogUpdate":_vm.dialogUpdate,"dataForum":_vm.dataForum},on:{"close":_vm.closeDialog,"fetch":_vm.fetchThread}}),_c('EditComment',{attrs:{"dialogComment":_vm.dialogComment,"dataComment":_vm.dataComment},on:{"close":_vm.closeDialog,"fetch":_vm.fetchThread}})],1)],1):_vm._e()]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"_100top pa-3 _full-w"},[_c('v-skeleton-loader',{attrs:{"type":"card-avatar, article, actions"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }