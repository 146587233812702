<template>
  <div class="d-flex _bg-default _80w _100vh">
    <div class="_100top pa-3 _full-w" v-if="!loading">
      <div v-if="thread.data">
        <v-card class="radius-card width__thread">
          <section>
            <div class="d-flex pa-3">
              <v-avatar class="mr-3">
                <img
                  :src="
                    thread.data.dataUser.photo_profile
                      ? `${env}/upload/photo_profile/${thread.data.dataUser.id}/${thread.data.dataUser.photo_profile}`
                      : `${env}/images/logo.png`
                  "
                  class="img-fit"
                  alt=""
                />
              </v-avatar>
              <div>
                <h4>{{ thread.data.dataUser.nama_lengkap }}</h4>
                <small class="grey--text">{{
                  $date(thread.data.forum.created_at).fromNow()
                }}</small>
              </div>
            </div>
          </section>
          <section class="px-5 py-3">
            <h3>{{ thread.data.forum.judul }}</h3>
          </section>
          <v-img
            v-if="thread.data.forum.image"
            :src="
              `${env}/forum/upload/${thread.data.dataUser.id}/${thread.data.forum.image}`
            "
            class="img-fit"
          ></v-img>
          <article class="pa-5">
            <div v-html="thread.data.forum.isi"></div>
          </article>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="blue"
              @click="toComment"
              content="Comment"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }"
            >
              <v-icon>mdi-comment</v-icon>
            </v-btn>

            <div class="px-3">
              <v-btn
                text
                color="blue"
                @click="like(thread.data.forum.id)"
                content="Like"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                v-if="!thread.data.isLike"
              >
                <v-icon>mdi-heart</v-icon>
                <span v-if="thread.data.likers > 0">
                  {{ thread.data.likers }}
                </span>
              </v-btn>
              <v-btn
                text
                color="red"
                @click="unlike(thread.data.forum.id)"
                v-if="thread.data.isLike"
              >
                <v-icon>mdi-heart</v-icon>
                <span v-if="thread.data.likers > 0">
                  {{ thread.data.likers }}
                </span>
              </v-btn>
            </div>

            <v-btn
              icon
              color="blue"
              v-if="thread.data.dataUser.id == id"
              @click="updateForum(thread.data.forum)"
              content="Edit"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              icon
              color="blue"
              v-if="thread.data.dataUser.id == id"
              @click="delForum(thread.data.forum.id)"
              content="delete"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-3 radius-card width__thread">
          <v-toolbar dense flat>
            <h3>Komentar</h3>
          </v-toolbar>
          <div
            class="d-flex algn-center justify-center flex-column"
            v-if="!thread.data.dataComment.length"
          >
            <img src="../../assets/img/404.svg" height="100px" alt="404" />
            <h4 class="text-center">No Comment</h4>
          </div>
          <div class="pa-3" v-else>
            <v-card
              class="radius-card pa-3 mb-2"
              v-for="(comment, idx) in thread.data.dataComment"
              :key="idx"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-avatar class="mr-3"
                    ><img
                      :src="
                        comment.photo_profile
                          ? `${env}/upload/photo_profile/${comment.user_id}/${comment.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                      class="img-fit"
                      alt=""
                  /></v-avatar>
                  <div class="d-flex align-center">
                    <h4>{{ comment.nama_lengkap }}</h4>
                    <span class="mx-1"><b>.</b></span>
                    <small class="grey--text">{{
                      $date(comment.created_at).fromNow()
                    }}</small>
                  </div>
                </div>
                <div v-if="comment.id == id">
                  <v-btn icon small color="blue" @click="editComment(comment)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    color="blue"
                    @click="delComment(comment.idComment)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
              </div>
              <div class="mt-2">
                <p>{{ comment.content }}</p>
              </div>
            </v-card>
          </div>
          <div class="d-flex flex-column pa-3">
            <v-textarea
              name="comment"
              id="fieldComment"
              hide-details
              v-model="content"
              label="Write something!"
              auto-grow
              solo
            ></v-textarea>
            <div>
              <v-spacer></v-spacer>
              <v-btn
                class="mt-3 blue"
                dark
                @click="comment(thread.data.forum.id)"
                ><v-icon class="mr-2">mdi-tooltip-check</v-icon>Comment</v-btn
              >
            </div>
            <div id="comment"></div>
          </div>
        </v-card>
        <div class="_100top pa-3 _full-w" v-if="loading">
          <h3 class="text-center">Loading...</h3>
        </div>
        <div class="pa-3">
          <UpdateForum
            v-bind:dialogUpdate="dialogUpdate"
            v-bind:dataForum="dataForum"
            @close="closeDialog"
            @fetch="fetchThread"
          />
          <EditComment
            v-bind:dialogComment="dialogComment"
            v-bind:dataComment="dataComment"
            @close="closeDialog"
            @fetch="fetchThread"
          />
        </div>
      </div>
    </div>
    <div class="_100top pa-3 _full-w" v-if="loading">
      <v-skeleton-loader
        type="card-avatar, article, actions"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import EditComment from "../../components/Psycholog/Modal/editComment.vue";
import UpdateForum from "../../components/Psycholog/Modal/updateForum.vue";

export default {
  components: { EditComment, UpdateForum },
  name: "thread",
  computed: {
    ...mapState({
      thread: (state) => state.forum.thread,
      env: (state) => state.API_URL,
      id: (state) => state.id,
    }),
  },
  data() {
    return {
      loading: true,
      content: "",
      dataComment: null,
      dialogComment: false,
      dialogUpdate: false,
      dataForum: null,
    };
  },
  mounted() {
    this.fetchThread(true);
  },

  methods: {
    fetchThread(reload) {
      if (reload) {
        this.loading = true;
      }
      let id = this.$route.params.id;
      this.$store.dispatch("forum/getThread", id).then(() => {
        this.loading = false;
      });
    },
    updateForum(data) {
      this.dataForum = data;
      this.dialogUpdate = true;
    },
    delForum(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("forum/deleteForum", id).then(() => {
            this.$router.push(`/psikolog/forum`);
            Swal.fire({
              icon: "success",
              title: "Thread terhapus!",
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          });
        }
      });
    },
    like(id) {
      this.$store.dispatch("forum/likeForum", id).then((data) => {
        this.fetchThread(false);
        console.log(data);
      });
    },
    unlike(id) {
      this.$store.dispatch("forum/unlikeForum", id).then(() => {
        this.fetchThread();
      });
    },
    toComment() {
      document.getElementById("fieldComment").focus();
      let el = document.getElementById("comment");
      el.scrollIntoView();
    },
    comment(id) {
      let data = {
        id: id,
        content: this.content,
      };
      this.$store.dispatch("forum/comment", data).then(() => {
        this.content = "";
        this.fetchThread();
        this.toComment();
      });
    },

    editComment(data) {
      this.dataComment = data;
      this.dialogComment = true;
    },

    closeDialog() {
      this.dialogUpdate = false;
      this.dialogComment = false;
    },

    delComment(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("forum/deleteComment", id).then(() => {
            this.fetchThread();
            Swal.fire({
              icon: "success",
              title: "Komentar terhapus!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000,
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
div >>> img {
  /* height: 200px; */
  width: 100%;
}
.width__thread {
  position: relative;
  width: 95%;
  box-sizing: border-box !important;
}
@media (max-width: 576px) {
  .width__thread {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .width__thread {
    width: 100%;
  }
}
</style>
